import React from "react";
import { Input } from "components";

const PersonalDetailsForm = ({ register, errors, onSubmit, children }) => {
  return (
    <form onSubmit={onSubmit}>
      <Input
        required
        register={register("firstName")}
        error={errors.firstName?.message}
        label="First Name"
        placeholder="Let's start with your name first"
      />
      <Input
        required
        register={register("lastName")}
        error={errors.lastName?.message}
        label="Last Name"
        placeholder="And your last name?"
      />
      <Input
        required
        type="email"
        register={register("email")}
        error={errors.email?.message}
        label="Email Address"
        placeholder="What email address can we reach you at? This is only to get in touch"
      />
      <Input
        required
        register={register("country")}
        error={errors.country?.message}
        label="Country"
        placeholder="What country and city do you live in?"
      />

      <Input
        required
        type="select"
        register={register("gender")}
        error={errors.gender?.message}
        label="Gender"
        defaultValue="Are you male or female?"
      >
        <option value="Are you male or female?" disabled hidden>
          Are you male or female?
        </option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </Input>

      <Input
        required
        type="date"
        register={register("dateOfBirth")}
        error={errors.dateOfBirth?.message}
        label="Date of Birth"
        placeholder="When were you born?"
      />

      {children}
    </form>
  );
};

export { PersonalDetailsForm };
