import React from "react";
import { Input } from "components";

const ProfessionalDetails = ({ register, onSubmit, errors, children }) => {
  return (
    <form onSubmit={onSubmit}>
      <Input
        type="select"
        register={register("employment")}
        error={errors.employment?.message}
        label="Employment status"
        defaultValue="What's your employment status?"
      >
        <option value="What's your employment status?" disabled hidden>
          What&apos;s your employment status?
        </option>
        <option value="Employed">Employed</option>
        <option value="Unemployed">Unemployed</option>
        <option value="Self employed">Self employed</option>
        <option value="Student">Student</option>
      </Input>

      <Input
        required
        register={register("company")}
        error={errors.company?.message}
        label="Company"
        placeholder="Where do you currently work?"
      />

      <Input
        type="textarea"
        register={register("expectations")}
        error={errors.expectations?.message}
        label="Expectations"
        placeholder="What are your expectations from us?"
      />

      <Input
        type="date"
        required
        register={register("availability")}
        error={errors.availability?.message}
        label="Availability"
        placeholder="What time of the day can we reach you?"
      />
      {children}
    </form>
  );
};

export { ProfessionalDetails };
