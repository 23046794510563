import React from "react";
import ctl from "@netlify/classnames-template-literals";
import CheckIcon from "svgs/form-check.svg";
import { Text, WLink } from "components";

const FormFeedback = () => {
  return (
    <div className={sectionWrapStyle}>
      <CheckIcon className="scale-[3] mb-8" />
      <Text
        variant="h4"
        value="Thanks for registering. You'll receive an update soon."
        color="primary-700"
        className={headingTextStyle}
      />
      <div>
        <Text
          variant="p18"
          value="We have received your details and a representative will reach out to you through your email address for for the course details"
          className={paragraphStyle}
        />

        <hr className={lineStyle} />
      </div>

      <Text
        variant="p16"
        value={
          <>
            If you need to speak to us, please call on{" "}
            <WLink href="tel:+2348009291829">+234 8009291829</WLink> or send an
            email to{" "}
            <WLink underline href="mailto:hello@wetse.org">
              hello@wetse.org
            </WLink>{" "}
          </>
        }
        className={contactTextStyle}
      />
    </div>
  );
};

const sectionWrapStyle = ctl(`
  flex 
  flex-col 
  items-center
`);
const headingTextStyle = ctl(`
  max-w-[303px] 
  md:max-w-[500px] 
  lg:max-w-full text-center
`);
const paragraphStyle = ctl(`
  mt-[25px] 
  lg:mb-[44px] 
  mb-3 lg:max-w-[664px] 
  md:max-w-[500px] 
  max-w-[330px] 
  text-center
`);
const lineStyle = ctl(`
  h-[2px] 
  bg-secondary-700 
  w-full
`);
const contactTextStyle = ctl(`
  lg:mt-[44px] 
  mt-6 
  lg:max-w-[492px] 
  max-w-[280px] 
  text-center
`);

export { FormFeedback };
