import React, { useState } from "react";
import ctl from "@netlify/classnames-template-literals";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Text, Container } from "components";
import { validationSchema } from "utils/validationSchema";
import CheckIcon from "svgs/form-check.svg";
import { PersonalDetailsForm } from "./personal-details-form";
import { ProfessionalDetails } from "./professional-details";
import { FormFeedback } from "./form-feedback";

const RegisterForm = () => {
  const stepsArrayKeys = Object.keys(steps);

  // Since we are dealing with an array, the length was reduced by one to avoid manipulations/subtractions in the code login
  const NO_OF_STEPS = stepsArrayKeys.length - 1;
  const [step, setStep] = useState(0);

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (step < NO_OF_STEPS) {
      setStep(step + 1);
    } else {
      try {
        await fetch(`/api/register`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(res => res.json())
          .then(data => {
            console.log({ data });
            if (data.error) {
              alert("Something went wrong, kindly try again");
            } else {
              setSubmitted(true);

              setTimeout(() => {
                setStep(0);
                reset();
                setSubmitted(false);
              }, 10 * 1000);
            }
          })
          .catch(error => {
            alert("Something went wrong, kindly try again");
          });
      } catch (e) {
        alert("Something went wrong, kindly try again");
      }
    }
  };

  const StepComponent = steps[stepsArrayKeys[step]];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema[step]),
  });

  return (
    <section className={sectionWrapStyle} id="form">
      <Container>
        {!submitted ? (
          <>
            <Text
              variant="h5"
              value="Submit your details"
              color="primary-900"
              weight="500"
            />
            <div className={formTitleWrapStyle}>
              {stepsArrayKeys.map((stepKey, index) => {
                return (
                  <>
                    <span
                      className={`${formTitleStyle} ${
                        index > step && "opacity-[.3]"
                      } `}
                    >
                      <CheckIcon />
                      <Text
                        variant="p14"
                        value={stepKey}
                        color="primary-500"
                        weight="700"
                      />
                    </span>
                    {index < NO_OF_STEPS && <div className={lineStyle}></div>}
                  </>
                );
              })}
            </div>

            <StepComponent
              register={register}
              errors={errors}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="lg:mt-[60px] mt-8">
                <Button
                  size="large"
                  text={step < NO_OF_STEPS ? "Continue" : "Register Now"}
                  disabled={isSubmitting}
                />
              </div>
            </StepComponent>
          </>
        ) : (
          <FormFeedback />
        )}
      </Container>
    </section>
  );
};

const steps = {
  "Personal Details": PersonalDetailsForm,
  "Professional Details": ProfessionalDetails,
};

const sectionWrapStyle = ctl(`
  bg-secondary-100
  flex
  justify-center
  lg:pt-[128px]
  pt-[63px]
  pb-[52px]
  lg:pb-[153px]
  lg:mt-[105px]
  lg:mb-[187px]
  mb-[104px]
`);
const formTitleWrapStyle = ctl(`
  flex
  items-center
  lg:gap-[18px]
  gap-3
  lg:py-[46px]
  pt-10
  pb-[33px]
`);
const formTitleStyle = ctl(`
  flex
  items-center
  lg:gap-[10px]
  gap-[6px]
`);
const lineStyle = ctl(`
  lg:w-[120px]
  sm:w-[82px]
  w-[35px]
  h-[1px]
  bg-secondary-700
`);

export { RegisterForm };
