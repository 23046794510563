import React from "react";
import { Br, PageHeaderTwo } from "components";
import { StaticImage } from "gatsby-plugin-image";

const RegisterPageHeader = () => {
  return (
    <PageHeaderTwo
      title={{
        text: (
          <>
            Unlock your <Br /> potentials
          </>
        ),
        width: "469px",
      }}
      description={{
        text: "Our services set you on the path to managing health, safety risk and sustainability in your profession and at your workplace at large. Fill the form below to explore any of our services.",
        width: "469px",
      }}
      image={
        <StaticImage
          alt="Man and woman in front of a laptop"
          src="../../../assets/images/pngs/register/Register.png"
          layout="fullWidth"
        />
      }
    />
  );
};

export { RegisterPageHeader };
