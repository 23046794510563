import React from "react";
import { Layout } from "components";
import { RegisterForm, RegisterPageHeader } from "templates/register";

const Register = () => {
  return (
    <Layout title="Register">
      <RegisterPageHeader />
      <RegisterForm />
    </Layout>
  );
};

export default Register;
